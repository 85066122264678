<template>
  <v-container class="fill-height">
    <v-row class="h-100">
      <v-col cols="12" md="8" class="d-none d-md-flex">
        <v-img
          class="mx-auto"
          src="@/assets/images/bg-login.png"
          max-width="60%"
          contain
        ></v-img>
      </v-col>
      <v-col cols="12" md="3" class="my-auto">

        <h2>Login</h2>
        <v-form @submit.prevent="submit">
          <v-text-field class="mt-12" v-model="form.username" name="username"
                        label="Benutzername"></v-text-field>
          <v-text-field class="mt-12" v-model="form.password" name="password" label="Passwort"
                        type="password"></v-text-field>
            <v-btn class="mt-12" outlined color="primary" type="submit" primary large>Anmelden</v-btn>
            <p v-if="showError" class="red--text mt-4" id="error">Benutzername oder Passwort ist falsch.</p>
        </v-form>
      </v-col>
      <v-col cols="12" md="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {},
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      showError: false
    }
  },
  methods: {
    ...mapActions(['LogIn']),
    async submit () {
      try {
        await this.LogIn({ username: this.form.username, password: this.form.password })
        this.$store.getters.isAdmin ? this.$router.push('admin') : this.$router.push('dashboard')
        this.showError = false
      } catch (error) {
        console.log(error)
        this.showError = true
      }
    }
  }
}
</script>
