import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height"},[_c(VRow,{staticClass:"h-100"},[_c(VCol,{staticClass:"d-none d-md-flex",attrs:{"cols":"12","md":"8"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":require("@/assets/images/bg-login.png"),"max-width":"60%","contain":""}})],1),_c(VCol,{staticClass:"my-auto",attrs:{"cols":"12","md":"3"}},[_c('h2',[_vm._v("Login")]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c(VTextField,{staticClass:"mt-12",attrs:{"name":"username","label":"Benutzername"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c(VTextField,{staticClass:"mt-12",attrs:{"name":"password","label":"Passwort","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c(VBtn,{staticClass:"mt-12",attrs:{"outlined":"","color":"primary","type":"submit","primary":"","large":""}},[_vm._v("Anmelden")]),(_vm.showError)?_c('p',{staticClass:"red--text mt-4",attrs:{"id":"error"}},[_vm._v("Benutzername oder Passwort ist falsch.")]):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"12","md":"1"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }